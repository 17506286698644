import React, {useState} from 'react';
import {
	Form,
	InputGroup,
	Container,
	Row,
	Col,
	Stack,
	Button
} from 'react-bootstrap';
import {useForm, useFormContext} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';

const Test = () => {
	const schema = yup.object().shape({
		firstname: yup.string().required('Please enter firstname')
	});

	const {
		register,
		watch,
		handleSubmit,
		formState: {errors}
	} = useForm({
		resolver: yupResolver(schema)
	});

  const [validated, setValidated] = useState(false);

  const API_PATH = 'https://quoteapi.mamp/quote';
  //const API_PATH = 'https://staging.motorcycle-insurance.com.au/test.php';
  
	const onSubmit = (e) => {
    console.log(e);

    axios({
      method: 'post',
      url: `${API_PATH}`,
      headers: { 'Content-Type': 'application/json' },
      data: e,
    }).then(result => {
      console.log(result)
    }).catch(error=>console.log(error))

//		setValidated(true);
	};

	return (
		<>
			<form validated={validated} onSubmit={handleSubmit(onSubmit)}>
				<Row className='mb-3'><Form.Group controlId='firstname'>
					<Form.Label>Firstname</Form.Label>
					<Form.Control
						type='Text'
						{...register('firstname')}
						isInvalid={errors.firstname}
					/>
					{errors.firstname && (
						<Form.Control.Feedback type='invalid'>
							{errors.firstname.message}
						</Form.Control.Feedback>
					)}
				</Form.Group>
        </Row>
        <Button type='submit'>Submit Quote</Button>

			</form>
		</>
	);
};

export default Test;